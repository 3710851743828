<template>
  <v-container>
    <v-card>
      <v-card-title> hola mundo </v-card-title>
      <v-card-text>
        <p>
          Ingresa img_entrega
          <v-file-input
            :model="img_entrega"
            accept="image/*"
            label="File input"
          ></v-file-input>
        </p>
        <p>
          Ingresa img_firma
          <v-file-input
            :model="img_firma"
            accept="image/*"
            label="File input"
          ></v-file-input>
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      quienEntrega: {
        pilotoNombre: '',
        pilotoPlaca: '',
        pilotoVehiculo: '',
        fecha: '',
        img_entrega: null,
        img_firma: null,
        lat: null,
        lon: null,
        accion: 'entregar',
      },
    };
  },
  methods: {
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguias: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
  },
};
</script>
